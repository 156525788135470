<template>
  <div class="page-wrap">
    <div class="monthly-payment-wrap">
      <PaymentPanel class="panel" :sourceData="sourceData"></PaymentPanel>
    </div>
    <PaymentList
      class="list"
      :dataList="this.$store.state.calcuRes[$route.query.type].group_detail"
      :mode="$route.query.mode"
    ></PaymentList>
  </div>
</template>

<script>
import PaymentPanel from "../components/PaymentPanel/index";
import PaymentList from "../components/PaymentList/index";

export default {
  components: {
    PaymentPanel,
    PaymentList,
  },
  created() {
    this.sourceDataInit(this.$store.state.calcuRes[this.$route.query.type])
  },
  data() {
    return {
      sourceData: {
        totalPay: "", // 总贷款额(单位元)
        totalInter: "", // 总利息(单位元)
        totalYear: "",  // 贷款年限
        firstMPay: "", // 首月还款
        lastMPay: "", // 末月还款
        paymentPrice: "", // 首付款
        totalPrincipal: "", // 总本金(单位元)
      },
    }
  },
  methods: {
    sourceDataInit(data) {
      const type = this.$route.query.type
      if(type === 'epi') {
        this.sourceData.firstMPay = data.epiFirstMonth.mPay
        this.sourceData.lastMPay = data.epiLastMonth.mPay
      }else {
        this.sourceData.firstMPay = data.epFirstMonth.mPay
        this.sourceData.lastMPay = data.epLastMonth.mPay
      }

      this.sourceData.totalPay = data.totalPay;
      this.sourceData.totalInter = data.totalInter;
      this.sourceData.totalYear = data.totalYear;
      this.sourceData.paymentPrice = data.paymentPrice;
      this.sourceData.totalPrincipal = data.totalPrincipal
    }
  }
};
</script>

<style scoped>
.monthly-payment-wrap {
  background-image: url("../assets/image/monthly-payment-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.panel {
  margin: 0 auto;
}
.list {
  margin: 0 auto;
  margin-top: 20px;
  width: 686px;
}
.page-wrap {
  padding-bottom: 20px;
}
</style>