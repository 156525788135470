<template>
  <div class="panel-wrap">
    <div class="panel-header" v-if="$route.query.cal === '按贷款总额'">
      <div class="header-title">贷款总额</div>
      <div class="header-body">
        <span>{{ (Number(sourceData.totalPrincipal) / 10000).toFixed(2) }}</span>
        <span>万</span>
      </div>
    </div>
    <div class="panel-header" v-if="$route.query.cal === '按房屋总价'">
      <div class="header-title">首付款</div>
      <div class="header-body">
        <span>{{ sourceData.paymentPrice | keepTwoDecimalFull }}</span>
        <span>万</span>
      </div>
    </div>
    <div class="panel-body">
      <div class="panel-body-item" v-if="$route.query.cal !== '按贷款总额'">
        <span>贷款总额</span>
        <span>{{ (Number(sourceData.totalPrincipal) / 10000).toFixed(2) | keepTwoDecimalFull }}万</span>
      </div>
      <div class="panel-body-item">
        <span>利息总额</span>
        <span>{{ (Number(sourceData.totalInter) / 10000).toFixed(2)}}万</span>
      </div>
      <div class="panel-body-item">
        <span>贷款年限</span>
        <span>{{ sourceData.totalYear }}年</span>
      </div>
      <div class="panel-body-item" v-if="$route.query.type === 'ep'">
        <span>首月还款</span>
        <span>{{ sourceData.firstMPay }}元</span>
      </div>
      <div class="panel-body-item" v-if="$route.query.type === 'ep'">
        <span>末月还款</span>
        <span>{{ sourceData.lastMPay }}元</span>
      </div>
      <div class="panel-body-item" v-if="$route.query.type === 'epi'">
        <span>月还款额</span>
        <span>{{ sourceData.firstMPay }}元</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sourceData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.panel-wrap {
  width: 686px;
}
.panel-header {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  padding-top: 87px;
  background-image: url('../../assets/image/line.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 298px;
}
.header-title {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  color: #666666;
  line-height: 32px;
  margin-bottom: 8px;
}
.header-body {
  text-align: center;
}
.header-body > span:nth-child(1) {
  font-size: 108px;
  font-weight: 500;
  color: #4A4A4A;
  line-height: 108px;
}
.header-body > span:nth-child(2) {
  font-size: 40px;
  font-weight: 600;
  color: #4A4A4A;
  line-height: 40px;
}
.panel-body {
  padding-top: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.panel-body-item {
  padding: 0 32px;
  font-size: 30px;
  display: flex;
  justify-content: space-between;
}
.panel-body-item span:first-child {
  font-weight: bold;
}
</style>