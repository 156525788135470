<template>
  <div class="list-wrap">
    <div v-if="mode === 'composition'">
      <div class="list-header">
        <div>期数</div>
        <div>总还款金额</div>
        <div>商贷还款</div>
        <div>公积金还款</div>
      </div>
      <div class="list-body">
        <div class="per-month" v-for="(item, index) in dataList" :key="index">
          <div>{{ item.name }}</div>
          <div>{{ item.mPay }}</div>
          <div>{{ item.mComPay }}</div>
          <div>{{ item.mFundPay }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="list-header">
        <div>期数</div>
        <div>月供</div>
        <div>本金</div>
        <div>利息</div>
        <div>剩余贷款</div>
      </div>
      <div class="list-body">
        <div class="per-month" v-for="(item, index) in dataList" :key="index">
          <div>{{ item.name }}</div>
          <div>{{ item.mPay }}</div>
          <div>{{ item.mPrincipal }}</div>
          <div>{{ item.mPayInter }}</div>
          <div>{{ item.mLeft }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  created() {
  },
};
</script>

<style scoped>
.list-wrap {
  border-radius: 16px;
  overflow: hidden;
}
.list-header {
  width: 100%;
  height: 80px;
  background: rgba(255, 204, 51, 0.08);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list-header > div {
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.list-body {
  background-color: #ffffff;
}
.year {
  height: 70px;
  color: #666666;
  font-size: 26px;
  line-height: 70px;
  padding-left: 48px;
  box-sizing: border-box;
}
.per-month {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 81px;
}
.per-month > div {
  color: #666666;
  font-size: 24px;
}
</style>